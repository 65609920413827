import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { HProps } from './typography';
import { selectTheme } from '../../duck/selectors';

import { State } from '../../../state/store';

const H4 = ({
    children,
    className,
    style,
    theme,
    ...rest
}: HProps): ReactElement => {
    return (
        <h4
            className={className}
            style={{ ...theme?.text?.h4, ...style }}
            {...rest}
        >
            {children}
        </h4>
    );
};

export default connect(
    (state: State) => ({
        theme: selectTheme(state),
    }),
    () => ({})
)(H4);
