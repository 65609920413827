/*
Eksempler:
    [a,b,c], og    => 'a, b og c'
    [a,b,c], eller => 'a, b eller c'
    [a]            => 'a'
    [a, b], og     => 'a og b'
    []             => ''
 */
export function formatListToNaturalLanguage(
    list: string[],
    delim: string,
    prefix?: string
) {
    if (list.length === 1) return list[0];
    else if (list.length > 1) {
        return `${prefix ? prefix + ' ' : ''}
                ${list.slice(0, list.length - 1).join(', ')}
                ${delim}
                ${list[list.length - 1]}`;
    } else return '';
}

export function phoneNumberToTelLink(number: string) {
    number = removeSpaces(number);
    if (!number) return number;
    else if (number[0] === '+') return `tel:${number}`;
    else if (number.startsWith('tel:')) return number;
    else return `tel:+47${number}`;
}

function removeSpaces(text: string) {
    return text.replaceAll(' ', '');
}

export function titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export function removeTrailing(str: string, toRemove: string) {
    const idx = str.search(toRemove);
    return idx > -1 ? str.substr(0, idx) : str;
}
