import { combineReducers } from 'redux';
import app from '../app/duck/reducers';
import admin from '../app/pages/Admin/duck/reducers';
import fileUploadReducer from '../app/pages/Admin/subPages/AdminImportPage/duck';
import invoicesReducer from '../app/pages/InvoicesPage/duck';
import locationsReducer from '../app/pages/LocationPage/duck';
import profileReducer from '../app/pages/ProfilePage/duck/reducers';
import reservationsReducer from '../app/pages/ReservationsPage/duck/reservationReducers';
import searchReducer from '../app/pages/SearchPage/duck/reducers';
import situationsReducer from '../app/pages/SolutionPage/duck/situationsReducers';
import errorReducer from '../app/pages/ErrorPage/duck/reducer';
import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';

export const rootReducer = combineReducers({
    profileReducer,
    invoicesReducer,
    fileUploadReducer,
    searchReducer,
    reservationsReducer,
    app,
    locationsReducer,
    admin,
    situations: situationsReducer,
    error: errorReducer,
});

export const buildStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                // TODO Disse skrur av en rekke advarsler og sånt.
                //  En eller annen dag burde vi fjerne disse og endelig fikse alle advarslene.
                serializableCheck: false,
                immutableCheck: false,
            }).concat([LogRocket.reduxMiddleware()]),
    });

export const store = buildStore();

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default rootReducer;
