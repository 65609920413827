import React from 'react';
import {
    PAGE_ADMIN,
    PAGE_ADMIN_CARS,
    PAGE_ADMIN_DAMAGE,
    PAGE_ADMIN_MEMBERSHIPS,
    PAGE_ADMIN_RESERVATIONS,
    PAGE_CHOOSE_CAR,
    PAGE_INVOICES,
    PAGE_RESERVATIONS,
    PAGE_ADMIN_HELP_OVERVIEW,
} from '../../duck/constants';
import NavButton from '../Buttons/NavButton';
import { checkIfSmallScreen } from '../../../utilities/utils';
import useEffectOnce from '../../../utilities/UseEffectOnce';
import { MobileAdminNavLinks } from '../../components/AdminNavButton/MobileAdminNavLinks';
import { Read } from '../Security/permissions';
import { AuthorizedNavButton } from '../Security/authorizedComponents';
import { Membership, Organization, Reservation } from '../../types/common';
import { Theme } from '../../../utilities/types';
import { connect } from 'react-redux';
import {
    selectAdmin,
    selectAuthentication,
    selectOrganizations,
    selectSelectedMembership,
} from '../../duck/selectors';
import {
    selectMembershipActiveReservations,
    selectMembershipPreLoadedReservations,
    selectUnseenReservations,
} from '../../pages/ReservationsPage/duck/selectors';
import { fetchActiveReservations } from '../../pages/ReservationsPage/duck/operations';
import {
    logout,
    setPage,
    setShouldAuthenticate,
    showHamburgerMenu,
} from '../../duck/operations';
import { AppDispatch, State } from '../../../state/store';

type Props = {
    theme: Theme;
    auth: string;
    admin?: boolean;
    selectedMembership?: Membership;
    membershipActiveReservations?: Reservation[];
    preLoadedReservations?: Reservation[];
    fetchActiveReservations: () => void;
    organizations: Organization[];
    showHamburgerMenuFunction: (show: boolean) => void;
    setPage: (pageName: string) => void;
    setShouldAuthenticate: (should: boolean) => void;
    logout: () => void;
    unseenReservations: Reservation[];
    showingSharedReservation?: boolean;
};

const NavItemsComponent = (props: Props) => {
    const activeMembership =
        props.auth &&
        props.selectedMembership &&
        props.selectedMembership.state === 'ACTIVE';

    const hasActiveReservations =
        props.membershipActiveReservations &&
        props.membershipActiveReservations.length > 0;

    const hasPreLoadedReservations =
        props.preLoadedReservations && props.preLoadedReservations.length > 0;

    useEffectOnce(() => {
        if (
            props.showingSharedReservation ||
            activeMembership ||
            hasActiveReservations ||
            hasPreLoadedReservations
        )
            return;
        props.fetchActiveReservations();
    });
    if (props.showingSharedReservation) return <></>;

    const renderHomePageButton = () => {
        if (!props.selectedMembership || !props.organizations) return <></>;
        const organization = props.organizations.filter(
            (o) => o.id === props.selectedMembership?.organizationId
        )[0];
        if (!organization || !organization.supportUrl) return <></>;

        let label = organization.supportUrl
            .replace('https://', '')
            .replace('/', '')
            .replace('www.', '');
        return (
            <NavButton
                key={'bildeleringenPageLink'}
                link={organization.supportUrl}
                external={true}
                newTab={true}
                label={label}
                theme={props.theme}
                disabled={!navigator.onLine}
            />
        );
    };

    return window.location.pathname.startsWith('/admin') &&
        !checkIfSmallScreen() ? (
        <>
            <NavButton
                key={'adminOverviewButton'}
                link={{
                    pathname: '/admin',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN);
                }}
                label={'Oversikt'}
                exact={true}
                theme={props.theme}
            />
            <AuthorizedNavButton
                key={'reservationsManagementButton'}
                link={{
                    pathname: '/admin/reservation-management/',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN_RESERVATIONS);
                }}
                label={'Reservasjoner'}
                exact={false}
                theme={props.theme}
                requireAny={[Read.RESERVATION, Read.MESSAGE]}
            />
            <NavButton
                key={'membershipManagementButton'}
                link={{
                    pathname: '/admin/user-management/',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN_MEMBERSHIPS);
                }}
                label={'Medlemmer'}
                exact={false}
                theme={props.theme}
            />
            <AuthorizedNavButton
                key={'fleetManagementButton'}
                link={{
                    pathname: '/admin/fleet-management/',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN_CARS);
                }}
                label={'Flåtestyring'}
                exact={false}
                theme={props.theme}
                requireAny={Read.FLEET_MANAGEMENT}
            />
            <AuthorizedNavButton
                key={'billingManagementButton'}
                link={{
                    pathname: '/admin/billing-management',
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_INVOICES);
                }}
                label={'Fakturering'}
                exact={false}
                theme={props.theme}
                requireAny={Read.BILLING}
            />
            <AuthorizedNavButton
                key={'damageManagementButton'}
                link={{
                    pathname: '/admin/damages',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN_DAMAGE);
                }}
                label={'Skader'}
                exact={false}
                theme={props.theme}
                requireAny={Read.MESSAGE}
            />
            <AuthorizedNavButton
                key={'helpManagementButton'}
                link={{
                    pathname: '/admin/help/overview',
                    state: { showMap: false },
                }}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN_HELP_OVERVIEW);
                }}
                label={'Hjelpesider'}
                exact={false}
                theme={props.theme}
                requireAny={Read.HELP}
            />
        </>
    ) : (
        <>
            {props.auth ? (
                <NavButton
                    key={'findcarButton'}
                    link={{
                        pathname: '/findcar',
                        state: { showMap: false },
                    }}
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                        props.setPage(PAGE_CHOOSE_CAR);
                    }}
                    label={'Velg bil'}
                    exact={false}
                    theme={props.theme}
                    disabled={!navigator.onLine}
                />
            ) : (
                ''
            )}
            {props.auth &&
            (activeMembership ||
                hasActiveReservations ||
                hasPreLoadedReservations) ? (
                <NavButton
                    key={'reservationsButton'}
                    link={'/reservations'}
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                        props.setPage(PAGE_RESERVATIONS);
                    }}
                    label={'Mine reservasjoner'}
                    exact={false}
                    theme={props.theme}
                    newItems={props.unseenReservations.length}
                />
            ) : (
                ''
            )}
            {props.auth &&
            props.selectedMembership &&
            props.selectedMembership.isOwner ? (
                <NavButton
                    key={'invoicesButton'}
                    link={'/invoices'}
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                        props.setPage(PAGE_RESERVATIONS);
                    }}
                    label={'Mine fakturaer'}
                    exact={true}
                    theme={props.theme}
                    disabled={!navigator.onLine}
                />
            ) : (
                ''
            )}
            <MobileAdminNavLinks
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN);
                }}
            />
            {!props.auth && (
                <div className={'u-displayDesktopOnly'}>
                    <NavButton
                        key={'logInOrOutButton'}
                        onClick={() => {
                            props.showHamburgerMenuFunction(false);
                            props.setShouldAuthenticate(true);
                            props.setPage(PAGE_CHOOSE_CAR);
                        }}
                        label={'Logg inn'}
                        theme={props.theme}
                        testId={'desktop-login-button'}
                        disabled={!navigator.onLine}
                    />
                </div>
            )}
            {renderHomePageButton()}
            <div className={'u-displayMobileOnly'}>
                <NavButton
                    key={'logInOrOutButton'}
                    onClick={() => {
                        props.showHamburgerMenuFunction(false);
                        if (!props.auth) {
                            props.setShouldAuthenticate(true);
                        } else {
                            props.logout();
                        }
                        props.setPage(PAGE_CHOOSE_CAR);
                    }}
                    label={props.auth ? 'Logg ut' : 'Logg inn'}
                    theme={props.theme}
                    testId={'mobile-loginandout-button'}
                />
            </div>
        </>
    );
};

const NavItems = connect(
    (state: State) => ({
        auth: selectAuthentication(state),
        admin: selectAdmin(state),
        selectedMembership: selectSelectedMembership(state),
        membershipActiveReservations: selectMembershipActiveReservations(state),
        preLoadedReservations: selectMembershipPreLoadedReservations(state),
        organizations: selectOrganizations(state),
        unseenReservations: selectUnseenReservations(state),
    }),
    (dispatch: AppDispatch) => ({
        fetchActiveReservations: (preloadAll?: boolean) =>
            dispatch(fetchActiveReservations(preloadAll)),
        showHamburgerMenuFunction: (show: boolean) =>
            dispatch(showHamburgerMenu(show)),
        setPage: (page: string) => dispatch(setPage(page)),
        setShouldAuthenticate: (should: boolean) =>
            dispatch(setShouldAuthenticate(should)),
        logout: () => dispatch(logout()),
    })
)(NavItemsComponent);

export default NavItems;
