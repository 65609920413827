import React, { ReactElement } from 'react';
import { hasAnyPermission, isDebugMode, Permission } from './permissions';
import { selectPermissions } from '../../duck/selectors';
import classNames from 'classnames';
import { useAppSelector } from '../../../state/hooks';

export type AuthorizedProps = {
    authorizedById: string;
    requireAny: Permission | Permission[];
};

type RenderProps = {
    children: ReactElement | ReactElement[] | string | undefined;
    fallback?: ReactElement | ReactElement[] | string | undefined;
} & AuthorizedProps;

export const RenderIfAuthorized = ({
    authorizedById,
    requireAny,
    children,
    fallback,
}: RenderProps) => {
    const granted = useAppSelector(selectPermissions) ?? [];
    const authorized = hasAnyPermission(granted, requireAny, authorizedById);

    if (isDebugMode()) {
        return (
            <div
                className={classNames('render_if_authorized', {
                    is_authorized: authorized,
                    is_unauthorized: !authorized,
                })}
            >
                {children}
            </div>
        );
    }

    if (authorized) return <>{children}</>;
    else if (fallback !== undefined) return <>{fallback}</>;
    else return <></>;
};

export default RenderIfAuthorized;
