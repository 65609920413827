import React from 'react';
import NavButton from '../Buttons/NavButton';
import { hasAnyPermission, isDebugMode } from './permissions';
import classNames from 'classnames';
import { AuthorizedProps, RenderIfAuthorized } from './RenderIfAuthorized';
import Button from '../Buttons/Button';
import { AdminTextAreaInput } from '../AdminTextareaInput';
import { AdminNewEntityLink } from '../../components/AdminNewEntityLink/AdminNewEntityLink';
import { connect } from 'react-redux';
import {
    selectPermissions,
    selectSelectedOrganization,
} from '../../duck/selectors';
import { CheckBox } from '../CheckBox/CheckBox';
import InputField from '../InputField/InputField';
import { AdminSaveButton } from '../Buttons/AdminSaveButton';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { DeleteWithConfirmationButton } from '../Buttons/DeleteWithConfirmationButton';
import { useAppSelector } from '../../../state/hooks';

function authorize<ChildProps>(Comp: React.ComponentType<ChildProps>) {
    return (props: ChildProps & AuthorizedProps) => (
        <RenderIfAuthorized
            authorizedById={props.authorizedById}
            requireAny={props.requireAny}
            children={<Comp {...props} />}
        />
    );
}

function authorizeOrDisable<ChildProps>(
    Comp: React.ComponentType<ChildProps>,
    ...disablingFields: (keyof ChildProps)[]
) {
    return (props: ChildProps & AuthorizedProps) => {
        const granted = useAppSelector(selectPermissions);
        if (disablingFields.map((f) => !!props[f]).includes(true))
            return <Comp {...props} />;

        const authorized = hasAnyPermission(
            granted,
            props.requireAny,
            props.authorizedById
        );

        const newProps = { ...props };

        disablingFields.forEach((f) => {
            // @ts-ignore
            newProps[f] = !authorized;
        });

        if (isDebugMode()) {
            return (
                <div
                    className={classNames('render_if_authorized', {
                        is_authorized: authorized,
                        is_disabled: !authorized,
                    })}
                >
                    <Comp {...newProps} />
                </div>
            );
        }

        return <Comp {...newProps} />;
    };
}

export const AuthorizedNavButton = connect((state: any) => ({
    authorizedById: selectSelectedOrganization(state)?.id,
}))(authorize(NavButton));

export const AuthorizedButton = authorize(Button);
export const AuthorizedAdminSaveButton = authorize(AdminSaveButton);
export const AuthorizedButtonWithIcon = authorize(ButtonWithIcon);
export const AuthorizedAdminNewEntityLink = authorize(AdminNewEntityLink);
export const AuthorizedDeleteWithConfirmationButton = authorize(
    DeleteWithConfirmationButton
);

export const AuthorizedOrDisabledAdminTextAreaInput = authorizeOrDisable(
    AdminTextAreaInput,
    'disabled'
);
export const AuthorizedOrDisabledCheckBox = authorizeOrDisable(
    CheckBox,
    'disabled'
);
export const AuthorizedOrDisabledInputField = authorizeOrDisable(
    InputField,
    'isDisabled',
    'readOnly'
);
