import React from 'react';
import { connect } from 'react-redux';
import { HProps } from './typography';
import { selectTheme } from '../../duck/selectors';

import { State } from '../../../state/store';

const H2 = ({
    children,
    className,
    style,
    theme,
    ...rest
}: HProps): JSX.Element => {
    return (
        <h2
            className={className}
            style={{ ...theme?.text?.h2, ...style }}
            {...rest}
        >
            {children}
        </h2>
    );
};

const mapStateToProps = (state: State) => ({
    theme: selectTheme(state),
});

export default connect(mapStateToProps, () => ({}))(H2);
