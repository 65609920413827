import React from 'react';
import { Membership } from '../../types/common';
import { ReactComponent as MembershipPausedIcon } from '../icon/membership_paused.svg';
import { ReactComponent as MembershipBlockedIcon } from '../icon/membership_blocked.svg';

type Props = {
    membership: Membership;
    statusText?: string;
    noIcon?: boolean;
};

export const MembershipStateIndicator = ({
    membership,
    statusText,
    noIcon,
}: Props) => {
    let color;
    let noTextTitle;
    let icon;

    switch (membership.state) {
        case 'ACTIVE':
            if (membership.externalIdCardVerificationRequired) {
                if (membership.externalIdCard) {
                    color = 'yellow';
                    noTextTitle = 'Venter på godkjenning av ID-kort';
                    icon = <MembershipPausedIcon />;
                } else {
                    color = 'red';
                    noTextTitle = 'Mangler ID-kort';
                    icon = <MembershipBlockedIcon />;
                }
            } else {
                color = 'green';
                noTextTitle = 'Aktivt';
            }
            break;
        case 'PAUSED':
            color = 'yellow';
            noTextTitle = 'Pauset';
            icon = <MembershipPausedIcon />;
            break;
        case 'PAYMENT_REQUIRED':
            color = 'red';
            noTextTitle = 'Betaling mangler';
            icon = <MembershipBlockedIcon />;
            break;
        case 'BLOCKED':
            color = 'red';
            noTextTitle = 'Sperret';
            icon = <MembershipBlockedIcon />;
            break;
        case 'CANCELED':
            color = 'red';
            noTextTitle = 'Avsluttet';
            icon = <MembershipBlockedIcon />;
            break;
        default:
            break;
    }

    return (
        <div
            role="status"
            className={`membershipStateIndicator stateIndicator-${color} ${
                statusText ? '' : 'stateIndicator-iconOnly'
            }`}
            title={statusText ? undefined : noTextTitle}
        >
            {!noIcon && icon}
            {statusText && (
                <span className={'stateIndicator-text'}>{statusText}</span>
            )}
        </div>
    );
};
