import React from 'react';
import 'normalize.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Styles.css';
import './react_dates_overrides.css';
import 'moment/locale/nb'; // Importing the locales that we need (eg. languages that we support)
import moment from 'moment';
import AdminNavItems from './common/Header/AdminNavItems';
import AppHeader from './common/Header/AppHeader';
import { CoronaPopup } from './components/Corona/CoronaPopup';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import { Router } from 'react-router-dom';
import Routes from './common/TopLevel/Routes';
import { FeatureToggler } from './components/FeatureToggler';
import history from './history';
import useEffectOnce from '../utilities/UseEffectOnce';
import useLayoutEffectOnce from '../utilities/UseLayoutEffectOnce';
import { OfflineAlert } from './common/TopLevel/OfflineAlert/OfflineAlert';
import ErrorBoundary from './common/TopLevel/ErrorBoundary';
import { isDebugMode } from './common/Security/permissions';
import { clearErrors } from './pages/ErrorPage/duck/reducer';
import { LoginModal } from './common/TopLevel/LoginModal';
import { Membership, Organization } from './types/common';
import { Theme } from '../utilities/types';
import { useAppDispatch } from '../state/hooks';

/*
    Set to DEV to use default gps-position and avoid fetching from entur
    Set to PROD to enable all features
 */
export const ENV = process.env.NODE_ENV;

type Props = {
    theme: Theme;

    auth?: string;
    admin?: boolean;
    organizations: Organization[];
    selectedMembership: Membership;
    selectedOrgId?: string;

    unauthorizedActionAttempted?: boolean;
    unauthorizedUrl?: string;

    login: (userName: string, password: string) => void;
    logout: () => void;
    initApp: () => void;
    logoutIfAuthExpired: (fetchUserInfoIfLoggedIn?: boolean) => any;
    fetchActiveReservations: (preloadAll: boolean) => void;
};

const App = (props: Props) => {
    useLayoutEffectOnce(() => {
        moment.locale('nb');
        if (props.initApp) props.initApp();
    });

    useEffectOnce(() => {
        // refresh cached active reservations every 10 min
        const cacheInterval = setInterval(
            () => props.fetchActiveReservations(true),
            1000 * 60 * 10
        );
        props
            .logoutIfAuthExpired(true)
            .then(() => props.fetchActiveReservations(true));
        return () => clearInterval(cacheInterval);
    });

    const dispatch = useAppDispatch();
    if (props.unauthorizedActionAttempted) {
        if (isDebugMode()) {
            console.error(
                `Unauthorized action attempted.${
                    props.unauthorizedUrl
                        ? `\nIllegal Url: ${props.unauthorizedUrl}`
                        : ''
                }\n`
            );
            dispatch(clearErrors());
        } else return <ErrorPage type={'UNAUTHORIZED'} />;
    }

    return (
        <ErrorBoundary>
            <OfflineAlert />
            <FeatureToggler toggleName={'coronapopup'} hideIfToggleOff>
                <CoronaPopup theme={props.theme} />
            </FeatureToggler>
            <Router history={history}>
                <div className={'app'}>
                    <AppHeader />
                    <AdminNavItems />
                    <main id="main" className={'main'}>
                        <LoginModal />
                        <Routes
                            theme={props.theme}
                            authorization={props.auth}
                            admin={props.admin}
                            organizations={props.organizations}
                            selectedOrgId={props.selectedOrgId}
                            login={props.login}
                            logout={props.logout}
                            selectedMembership={props.selectedMembership}
                        />
                    </main>
                    <div className={'desktopBackground'} />
                </div>
            </Router>
        </ErrorBoundary>
    );
};

export default App;
