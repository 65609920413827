import actions from '../app/duck/actions';
import {
    selectAuthentication,
    selectSelectedOrganization,
} from '../app/duck/selectors';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { reportUnauthorizedAttempt } from '../app/pages/ErrorPage/duck/reducer';
import { setShouldAuthenticate } from '../app/duck/operations';
import { ApiCallResult } from '../app/types/application';
import { apiCallError } from './constants';
import mime from 'mime-types';
import { AppDispatch, State } from '../state/store';
import { useAppDispatch, useAppSelector } from '../state/hooks';

export function get(
    url: string | RequestInfo,
    apiCallObject: ApiCallObject,
    auth?: string
): ApiCallPromise {
    return apiCallObject
        .setCallParams(url, {
            headers: {
                authorization: auth ?? '',
                'content-type': 'application/json',
            },
            method: 'GET',
        })
        .issue();
}

export function post(
    url: string | RequestInfo,
    apiCallObject: ApiCallObject,
    body?: any,
    auth?: string
): ApiCallPromise {
    return apiCallObject
        .setCallParams(url, {
            body: body ? JSON.stringify(body) : null,
            headers: {
                authorization: auth ?? '',
                'content-type': 'application/json',
            },
            method: 'POST',
        })
        .issue();
}

export function put(
    url: string | RequestInfo,
    apiCallObject: ApiCallObject,
    body?: any,
    auth?: string
): ApiCallPromise {
    return apiCallObject
        .setCallParams(url, {
            body: body ? JSON.stringify(body) : null,
            headers: {
                authorization: auth ?? '',
                'content-type': 'application/json',
            },
            method: 'PUT',
        })
        .issue();
}

export function deleet(
    url: string | RequestInfo,
    apiCallObject: ApiCallObject,
    body?: any,
    auth?: string
): ApiCallPromise {
    return apiCallObject
        .setCallParams(url, {
            body: body ? JSON.stringify(body) : null,
            headers: {
                authorization: auth ?? '',
                'content-type': 'application/json',
            },
            method: 'DELETE',
        })
        .issue();
}
const validMimeTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export function exportToExcel(
    url: string,
    filename: string,
    queryString?: string
) {
    return (dispatch: AppDispatch, getState: () => State) => {
        const orgId = selectSelectedOrganization(getState())?.id;
        return get(
            `${url}?orgId=${orgId}${queryString ? `&${queryString}` : ''}`,
            createApiCallObject(dispatch, filename),
            selectAuthentication(getState())
        ).then((response: Response) => {
            handleErrors(response, dispatch).then((r) =>
                r.blob().then((blob) => {
                    if (validMimeTypes.includes(blob.type)) {
                        const ext = mime.extension(blob.type);

                        if (ext) {
                            const objectUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = objectUrl;
                            link.setAttribute('download', `${filename}.${ext}`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                        }
                    }
                })
            );
        });
    };
}

export type ApiCallObject = {
    dispatch: AppDispatch;
    url: string | RequestInfo;
    request?: RequestInit;
    issuer: string;
    setCallParams: (
        url: string | RequestInfo,
        request: RequestInit
    ) => ApiCallObject;
    issue: () => ApiCallPromise;
};

export type ApiCallPromise = {
    request?: string | RequestInit;
    issuer?: string;
} & Promise<Response>;

export function createApiCallObject(
    dispatch: AppDispatch,
    issuer: string
): ApiCallObject {
    return {
        ...defaultApiCallObject,
        dispatch: dispatch,
        issuer: issuer,
    };
}

// @ts-ignore it's really a partial object, with fields we can't provide yet
const defaultApiCallObject: ApiCallObject = {
    url: '',
    setCallParams: function (url: string | RequestInfo, request: RequestInit) {
        this.url = url;
        this.request = request;
        return this;
    },
    issue: function () {
        if (this.dispatch) this.dispatch(actions.apiCall(this));
        const promise: ApiCallPromise = fetch(this.url, this.request);
        promise.request = this.request;
        promise.issuer = this.issuer;
        return promise;
    },
};

export function handleErrors(
    response: Response,
    dispatch: AppDispatch
): Promise<Response> {
    return new Promise((resolve, reject) => {
        if (!response.ok) {
            if (response.status === 401) {
                dispatch && dispatch(setShouldAuthenticate(true));
                reject(Error('unauthenticated'));
            } else if (response.status === 403) {
                dispatch && dispatch(reportUnauthorizedAttempt(response.url));
                reject(Error('unauthorized'));
            } else {
                reject(Error(response.statusText));
            }
        }
        resolve(response);
    });
}

type Options<Data> = {
    parseData?: (data: any) => Data;
} & UseQueryOptions<any, any, Data>;

export function useAuthenticatedQuery<Data>(
    url: string,
    options?: Options<Data>
) {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<State, string>(selectAuthentication);
    const { parseData, queryKey, ...rest } = { ...options };
    return useQuery<Data>({
        ...rest,
        queryKey: queryKey ?? [url],
        queryFn: async () => {
            return fetch(url, {
                headers: {
                    authorization: auth,
                    method: 'GET',
                },
            }).then(
                (res) =>
                    new Promise((resolve, reject) => {
                        if (res.status === 403) {
                            dispatch(reportUnauthorizedAttempt(url));
                            return reject();
                        }
                        if (res.status === 401) {
                            dispatch(setShouldAuthenticate(true));
                            return reject();
                        } else
                            return res
                                .json()
                                .then(
                                    (o) =>
                                        resolve(
                                            options?.parseData
                                                ? options?.parseData(o)
                                                : o
                                        ),
                                    reject
                                );
                    })
            );
        },
    });
}

export function apiCallResultIsSuccess(
    results: ApiCallResult | ApiCallResult[]
): boolean {
    if (!Array.isArray(results)) return results !== apiCallError;
    else return !results.includes(apiCallError);
}
