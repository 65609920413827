import 'react-app-polyfill/ie9';
import React, { lazy, Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store } from './state/store';
import PageLoader from './PageLoader';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { wrapperBridgeExists } from './utilities/wrapperUtils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

if (wrapperBridgeExists()) {
    LogRocket.init('rsve9o/nodeleapp');
    setupLogRocketReact(LogRocket);
}

const App = lazy(() => import('./app'));

const queryClient = new QueryClient();

const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <PersistGate loading={<div />} persistor={persistStore(store)}>
                <Suspense fallback={<PageLoader />}>
                    <App />
                </Suspense>
            </PersistGate>
        </QueryClientProvider>
    </Provider>
);

serviceWorkerRegistration.register();
