import React from 'react';
import Button from '../Buttons/Button';
import { IssuesApiCall } from '../IssuesApiCall';
import Modal from '../Modal';
import { H4 } from '../Typography';

const AdminModalConfirmComponent = ({
    ownerId,
    issueCall,
    heading,
    text,
    content,
    onClose,
    showCancelButton,
    theme,
    styleOverride,
}) => (
    <Modal closeModal={onClose}>
        <section className="adminModal">
            <H4>{heading}</H4>
            {text && (
                <div className="adminModal__body" style={theme.text.body2}>
                    {text}
                </div>
            )}
            {content && content}
            <div className="adminModal__buttonWrapper">
                <Button
                    label={'Bekreft'}
                    onClick={() => {
                        issueCall([ownerId]);
                    }}
                    buttonType={
                        showCancelButton ? 'secondaryButton' : 'primary'
                    }
                />
                {showCancelButton ? (
                    <Button
                        label={'Avbryt'}
                        onClick={onClose}
                        buttonType={'primary'}
                    />
                ) : (
                    ''
                )}
            </div>
        </section>
    </Modal>
);

export const AdminModalConfirm = IssuesApiCall(AdminModalConfirmComponent);
