import React from 'react';
import { Link } from 'react-router-dom';
import { Organization } from '../../types/common';
import { connect } from 'react-redux';
import { setSelectedOrganization } from '../../duck/operations';
import {
    selectAdmin,
    selectAuthentication,
    selectOrganizations,
} from '../../duck/selectors';
import { AppDispatch, State } from '../../../state/store';

type Props = {
    auth: string;
    admin?: boolean;
    organizations: Organization[];
    setSelectedOrganization: (org: Organization) => void;
    onClick: () => void;
};

const MobileAdminNavLinksComponent = (props: Props) => {
    if (
        !props.auth ||
        !props.admin ||
        !props.organizations ||
        props.organizations.length === 0
    )
        return <></>;

    if (!navigator.onLine) {
        return (
            <div
                className={`navButton navButton--disabled u-displayMobileOnly`}
            >
                <div className={'navButton__navLabel'}>Admin</div>
            </div>
        );
    }

    const renderLink = (org: Organization) => (
        <Link
            key={org.name}
            className="navButton u-displayMobileOnly"
            to={'/admin'}
            onClick={() => {
                props.onClick();
                props.setSelectedOrganization(org);
            }}
            style={{ color: 'white' }}
        >
            <div className="navButton__navLabel">
                Admin{props.organizations.length > 1 ? `: ${org.name}` : ''}
            </div>
        </Link>
    );

    return <>{props.organizations.map(renderLink)}</>;
};

export const MobileAdminNavLinks = connect(
    (state: State) => ({
        auth: selectAuthentication(state),
        admin: selectAdmin(state),
        organizations: selectOrganizations(state),
    }),
    (dispatch: AppDispatch) => ({
        setSelectedOrganization: (org: Organization) =>
            dispatch(setSelectedOrganization(org)),
    })
)(MobileAdminNavLinksComponent);
