import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';

export interface ModelState {
    fetchAdminModelsResult?: string;
    fetchingAdminModels?: boolean;
    adminModels?: object[];

    fetchAdminModelsMetadataResult?: string;
    fetchingAdminModelsMetadata?: boolean;
    adminModelsMetadata?: object;

    fetchAdminModelResult?: string;
    fetchingAdminModel?: boolean;
    adminModel?: object;

    putAdminModelResult?: string;
    puttingAdminModel?: boolean;

    creatingModel?: boolean;
    createModelResult?: string;
    newModelId?: string;

    fetchingAdminCarCategories?: boolean;
    fetchAdminCarCategoriesResult?: string;
    adminCarCategories?: object[];

    fetchingChargingCableTypes?: boolean;
    fetchChargingCableTypesResult?: string;
    chargingCableTypes?: object[];

    fetchingAdminModelTemplate?: boolean;
    fetchAdminModelTemplateResult?: string;
    adminModelTemplate?: object;

    fetchingCarImages?: boolean;
    fetchCarImagesResult?: string;
    carImages?: object[];

    fetchingCarImagesMetadata?: boolean;
    fetchCarImagesMetadataResult?: string;
    carImagesMetadata?: object;

    creatingCarImage?: boolean;
    createCarImageResult?: string;

    updatingCarImage?: boolean;
    updateCarImageResult?: string;

    deletingCarImage?: boolean;
    deleteCarImageResult?: string;
}

const initialState: ModelState = {};

const adminModelSlice = createSlice({
    name: 'adminModels',
    initialState,
    reducers: {
        fetchAdminModelsPending(state: ModelState) {
            state.fetchingAdminModels = true;
            state.fetchAdminModelsResult = undefined;
        },
        fetchAdminModelsCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingAdminModels = false;
            state.fetchAdminModelsResult = apiCallSuccess;
            state.adminModels = action.payload;
        },
        fetchAdminModelsError(state: ModelState) {
            state.fetchingAdminModels = false;
            state.fetchAdminModelsResult = apiCallError;
        },

        fetchAdminModelsMetadataPending(state: ModelState) {
            state.fetchingAdminModelsMetadata = true;
            state.fetchAdminModelsMetadataResult = undefined;
        },
        fetchAdminModelsMetadataCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingAdminModelsMetadata = false;
            state.fetchAdminModelsMetadataResult = apiCallSuccess;
            state.adminModelsMetadata = action.payload;
        },
        fetchAdminModelsMetadataError(state: ModelState) {
            state.fetchingAdminModelsMetadata = false;
            state.fetchAdminModelsMetadataResult = apiCallError;
        },

        fetchAdminModelPending(state: ModelState) {
            state.fetchingAdminModel = true;
            state.fetchAdminModelResult = undefined;
        },
        fetchAdminModelCompleted(
            state: ModelState,
            action: PayloadAction<object>
        ) {
            state.fetchingAdminModel = false;
            state.fetchAdminModelResult = apiCallSuccess;
            state.adminModel = action.payload;
        },
        fetchAdminModelError(state: ModelState) {
            state.fetchingAdminModel = false;
            state.fetchAdminModelResult = apiCallError;
        },

        putAdminModelPending(state: ModelState) {
            state.puttingAdminModel = true;
            state.putAdminModelResult = undefined;
        },
        putAdminModelCompleted(state: ModelState) {
            state.puttingAdminModel = false;
            state.putAdminModelResult = apiCallSuccess;
        },
        putAdminModelError(state: ModelState) {
            state.puttingAdminModel = false;
            state.putAdminModelResult = apiCallError;
        },
        resetPutAdminModelResult(state: ModelState) {
            state.puttingAdminModel = false;
            state.putAdminModelResult = undefined;
        },

        createAdminModelPending(state: ModelState) {
            state.creatingModel = true;
            state.createModelResult = undefined;
        },
        createAdminModelCompleted(
            state: ModelState,
            action: PayloadAction<string>
        ) {
            state.creatingModel = false;
            state.createModelResult = apiCallSuccess;
            state.newModelId = action.payload;
        },
        createAdminModelError(state: ModelState) {
            state.creatingModel = false;
            state.createModelResult = apiCallError;
        },
        resetCreateAdminModelState(state: ModelState) {
            state.creatingModel = false;
            state.createModelResult = undefined;
            state.newModelId = undefined;
        },

        fetchAdminCarCategoriesPending(state: ModelState) {
            state.fetchingAdminCarCategories = true;
            state.fetchAdminCarCategoriesResult = undefined;
        },
        fetchAdminCarCategoriesCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingAdminCarCategories = false;
            state.fetchAdminCarCategoriesResult = apiCallSuccess;
            state.adminCarCategories = action.payload;
        },
        fetchAdminCarCategoriesError(state: ModelState) {
            state.fetchingAdminCarCategories = false;
            state.fetchAdminCarCategoriesResult = apiCallError;
        },

        fetchChargingCableTypesPending(state: ModelState) {
            state.fetchingChargingCableTypes = true;
            state.fetchChargingCableTypesResult = undefined;
        },
        fetchChargingCableTypesCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingChargingCableTypes = false;
            state.fetchChargingCableTypesResult = apiCallSuccess;
            state.chargingCableTypes = action.payload;
        },
        fetchChargingCableTypesError(state: ModelState) {
            state.fetchingChargingCableTypes = false;
            state.fetchChargingCableTypesResult = apiCallError;
        },

        fetchAdminModelTemplatePending(state: ModelState) {
            state.fetchingAdminModelTemplate = true;
            state.fetchAdminModelTemplateResult = undefined;
        },
        fetchAdminModelTemplateCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingAdminModelTemplate = false;
            state.fetchAdminModelTemplateResult = apiCallSuccess;
            state.adminModelTemplate = action.payload;
        },
        fetchAdminModelTemplateError(state: ModelState) {
            state.fetchingAdminModelTemplate = false;
            state.fetchAdminModelTemplateResult = apiCallError;
        },

        fetchCarImagesPending(state: ModelState) {
            state.fetchCarImagesResult = undefined;
            state.fetchingCarImages = true;
        },
        fetchCarImagesCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchCarImagesResult = apiCallSuccess;
            state.fetchingCarImages = false;
            state.carImages = action.payload;
        },
        fetchCarImagesError(state: ModelState) {
            state.fetchCarImagesResult = apiCallError;
            state.fetchingCarImages = false;
        },
        fetchCarImagesMetadataPending(state: ModelState) {
            state.fetchCarImagesMetadataResult = undefined;
            state.fetchingCarImagesMetadata = true;
        },
        fetchCarImagesMetadataCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.fetchCarImagesMetadataResult = apiCallSuccess;
            state.fetchingCarImagesMetadata = false;
            state.carImagesMetadata = action.payload;
        },
        fetchCarImagesMetadataError(state: ModelState) {
            state.fetchCarImagesMetadataResult = apiCallError;
            state.fetchingCarImagesMetadata = false;
        },

        creatingCarImage(state: ModelState) {
            state.creatingCarImage = true;
            state.createCarImageResult = undefined;
        },

        createCarImageCompleted(state: ModelState) {
            state.creatingCarImage = false;
            state.createCarImageResult = apiCallSuccess;
        },

        createCarImageError(state: ModelState) {
            state.creatingCarImage = false;
            state.createCarImageResult = apiCallError;
        },

        updatingCarImage(state: ModelState) {
            state.updatingCarImage = true;
            state.updateCarImageResult = undefined;
        },

        updateCarImageCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.updatingCarImage = false;
            state.updateCarImageResult = apiCallSuccess;
            state.carImages = action.payload;
        },

        updateCarImageError(state: ModelState) {
            state.updatingCarImage = false;
            state.updateCarImageResult = apiCallError;
        },

        deleteCarImagePending(state: ModelState) {
            state.deleteCarImageResult = undefined;
            state.deletingCarImage = true;
        },
        deleteCarImageCompleted(
            state: ModelState,
            action: PayloadAction<object[]>
        ) {
            state.deleteCarImageResult = apiCallSuccess;
            state.deletingCarImage = false;
            state.carImages = action.payload;
        },
        deleteCarImageError(state: ModelState) {
            state.deleteCarImageResult = apiCallError;
            state.deletingCarImage = false;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    fetchAdminModelsPending,
    fetchAdminModelsCompleted,
    fetchAdminModelsError,
    fetchAdminModelsMetadataPending,
    fetchAdminModelsMetadataCompleted,
    fetchAdminModelsMetadataError,
    fetchAdminModelPending,
    fetchAdminModelCompleted,
    fetchAdminModelError,
    putAdminModelPending,
    putAdminModelCompleted,
    putAdminModelError,
    resetPutAdminModelResult,
    createAdminModelPending,
    createAdminModelCompleted,
    createAdminModelError,
    resetCreateAdminModelState,
    fetchAdminCarCategoriesPending,
    fetchAdminCarCategoriesCompleted,
    fetchAdminCarCategoriesError,
    fetchChargingCableTypesPending,
    fetchChargingCableTypesCompleted,
    fetchChargingCableTypesError,
    fetchAdminModelTemplatePending,
    fetchAdminModelTemplateCompleted,
    fetchAdminModelTemplateError,
    fetchCarImagesPending,
    fetchCarImagesCompleted,
    fetchCarImagesError,
    fetchCarImagesMetadataPending,
    fetchCarImagesMetadataCompleted,
    fetchCarImagesMetadataError,
    updatingCarImage,
    updateCarImageCompleted,
    updateCarImageError,
    creatingCarImage,
    createCarImageCompleted,
    createCarImageError,
    deleteCarImagePending,
    deleteCarImageCompleted,
    deleteCarImageError,
} = adminModelSlice.actions;

export default adminModelSlice.reducer;
