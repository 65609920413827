import React from 'react';
import classNames from 'classnames';

type Props = {
    checked?: boolean;
    infoTextElement?: any;
    onClick?: () => void;
    disabled?: boolean;
    ariaLabel?: string;
    wrapperClass?: string;
};

export const CheckBox = ({
    checked,
    infoTextElement,
    onClick,
    disabled,
    ariaLabel,
    wrapperClass,
}: Props) => {
    return (
        <label
            aria-label={ariaLabel}
            className={classNames('checkBox', wrapperClass)}
        >
            <input
                disabled={disabled}
                onChange={() => disabled || !onClick || onClick()}
                type="checkbox"
                checked={checked}
                readOnly={true}
            />
            <span>{infoTextElement}</span>
        </label>
    );
};
