import React from 'react';
import { connect } from 'react-redux';
import { HProps } from './typography';
import { selectTheme } from '../../duck/selectors';

import { State } from '../../../state/store';

const H3 = ({
    children,
    className,
    style,
    theme,
    ...rest
}: HProps): JSX.Element => {
    return (
        <h3
            className={className}
            style={{ ...theme?.text?.h3, ...style }}
            {...rest}
        >
            {children}
        </h3>
    );
};

const mapStateToProps = (state: State) => ({
    theme: selectTheme(state),
});

export default connect(mapStateToProps, () => ({}))(H3);
