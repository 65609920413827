import {
    useDispatch,
    UseSelector,
    UseDispatch,
    useSelector,
} from 'react-redux';
import type { AppDispatch, State } from './store';

export const useAppDispatch: UseDispatch<AppDispatch> = useDispatch.withTypes<
    AppDispatch
>();
export const useAppSelector: UseSelector<State> = useSelector.withTypes<
    State
>();
