import React, { Component, Fragment } from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { ReactComponent as Calendar } from '../icon/calendar.svg';
import { ReactComponent as PasswordShow } from '../icon/passwordShow.svg';
import { ReactComponent as PasswordHide } from '../icon/passwordHide.svg';
import { ReactComponent as ArrowLeft } from '../icon/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../icon/arrowRight.svg';

import {
    generateInputId,
    parseDashSeparatedDate,
    parseDotSeparatedDate,
    parseNotSeparatedDate,
    validEmail,
    validPhoneNumber,
} from '../../../utilities/utils';
import { SingleDatePicker } from 'react-dates';
import { connect } from 'react-redux';
import { selectTheme } from '../../duck/selectors';

class InputFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            focused: false,
            valid: true,
            errorMessage: undefined,
            dateInputFocused: false,
            dateSelectFocused: false,
            dateSelectHasBeenOpened: false,
            showPassword: false,
        };
    }

    render() {
        let {
            theme,
            label,
            placeHolderText,
            className,
            type,
            id,
            defaultValue,
            ariaLabel,
            helpText,
            customComponent,
            isDisabled,
            readOnly, // TODO isDisabled and readOnly do the same, they really should just be one prop
            small,
            externalValidationErrorMessage,
            autoFocus,
            name,
            testId,
            maxLength,
            autoComplete,
        } = this.props;

        let style =
            this.state.valid && !externalValidationErrorMessage
                ? this.state.focused && !readOnly
                    ? {
                          borderColor: theme.colors.links_and_clickables,
                          boxShadow: '0 0 0 1px #016CC5',
                      }
                    : {
                          borderColor: this.state.hover
                              ? theme.colors.disabled__wcag
                              : theme.colors.disabled,
                      }
                : { borderColor: theme.colors.alert_error };

        id = id ? id : generateInputId();

        return (
            <div
                className={classnames('inputField', className, {
                    inputField__static: ['DATE'].includes(type),
                })}
            >
                {label ? (
                    <label
                        htmlFor={id}
                        className={'inputField__label'}
                        style={theme.text.inputField__label}
                    >
                        {label}
                    </label>
                ) : (
                    ''
                )}
                <div
                    className={
                        type === 'TEXT_AREA'
                            ? 'inputField__input inputField__input--textArea'
                            : type === 'TEXT_AREA--BIG'
                            ? 'inputField__input inputField__input--bigTextArea'
                            : !!small
                            ? 'inputField__input inputField__input--small'
                            : 'inputField__input'
                        // : `inputField__input ${type === 'PASSWORD' ? 'inputField__input--password' : ''}`
                    }
                    style={{
                        ...theme.text.inputField__input,
                    }}
                    onKeyDown={this.onKeyDown}
                >
                    {type === 'CUSTOM_CONTENT' ? (
                        <Fragment>{customComponent}</Fragment>
                    ) : type === 'TEXT_AREA' || type === 'TEXT_AREA--BIG' ? (
                        <textarea
                            id={id}
                            data-test={testId}
                            className={
                                'inputField__text inputField__text--area'
                            }
                            aria-label={ariaLabel}
                            autoComplete={autoComplete}
                            value={defaultValue}
                            onChange={(e) => this.onChange(e.target.value)}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            disabled={isDisabled}
                            style={style}
                            placeholder={placeHolderText}
                            onMouseEnter={() => this.setState({ hover: true })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        />
                    ) : type === 'DATE' || type === 'DATE--NO_PICKER' ? (
                        this.renderDateSelect(style)
                    ) : (
                        //type TEXT, PHONE_NUMBER, NUMBER, EMAIL, PASSWORD, FULL_NAME
                        <Fragment>
                            <input
                                id={id}
                                data-test={testId}
                                className={classnames('inputField__text', {
                                    'inputField__text--readOnly': readOnly,
                                    'inputField__number--noSpinner':
                                        type === 'NUMBER',
                                })}
                                aria-label={ariaLabel}
                                autoComplete={
                                    autoComplete
                                        ? autoComplete
                                        : type === 'EMAIL'
                                        ? 'email'
                                        : type === 'FULL_NAME'
                                        ? 'name'
                                        : type === 'PHONE_NUMBER'
                                        ? 'tel'
                                        : null
                                }
                                type={
                                    type === 'EMAIL'
                                        ? 'email'
                                        : type === 'PHONE_NUMBER'
                                        ? 'tel'
                                        : type === 'PASSWORD' &&
                                          !this.state.showPassword
                                        ? 'password'
                                        : type === 'NUMBER_STRICT' ||
                                          type === 'NUMBER'
                                        ? 'number' // Gjør at vi rendrer en spinner og at bokstaver ikke fungerer
                                        : 'text'
                                }
                                pattern={
                                    type === 'NUMBER_STRICT'
                                        ? '\\d*'
                                        : undefined
                                }
                                value={defaultValue}
                                readOnly={readOnly || isDisabled}
                                onChange={(e) => {
                                    if (
                                        !readOnly &&
                                        !isDisabled &&
                                        (maxLength === undefined ||
                                            e.target.value?.length <= maxLength)
                                    ) {
                                        this.onChange(e.target.value);
                                    }
                                }}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                style={style}
                                placeholder={placeHolderText}
                                onMouseEnter={() =>
                                    this.setState({ hover: true })
                                }
                                onMouseLeave={() =>
                                    this.setState({ hover: false })
                                }
                                autoFocus={autoFocus}
                                name={name}
                                maxLength={maxLength}
                                disabled={isDisabled || readOnly}
                            />
                            {type === 'PASSWORD'
                                ? this.renderShowPasswordToggle()
                                : ''}
                        </Fragment>
                    )}
                </div>
                <div className="inputField__helpText">
                    <p style={theme.text.inputField__helpText}>
                        {this.state.errorMessage ||
                        externalValidationErrorMessage ? (
                            <span
                                style={{ color: theme.colors.alert_error }}
                                role="alert"
                            >
                                {externalValidationErrorMessage
                                    ? externalValidationErrorMessage
                                    : this.state.errorMessage}
                            </span>
                        ) : (
                            helpText
                        )}
                    </p>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.addEventListener('touchstart', this.handleTouchOutside, {
            passive: false,
        });
        if (this.props.preValidate) {
            this.onChange(
                this.props.defaultValue ? this.props.defaultValue : ''
            );
        }
    };
    renderShowPasswordToggle = () => (
        <button
            className="toggleShowPasswordButton"
            onClick={() => {
                this.setState({
                    showPassword: !this.state.showPassword,
                });
            }}
        >
            {this.state.showPassword ? <PasswordShow /> : <PasswordHide />}
        </button>
    );

    renderDateSelect = (style) => {
        let dateObject = moment.isMoment(this.props.value)
            ? this.props.value
            : undefined;

        let stringObject = dateObject
            ? dateObject.format('DD.MM.YYYY')
            : this.props.value;

        return (
            <div
                className="inputField__date"
                style={style}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        this.dateInput.blur();
                        this.setState({
                            dateInputFocused: false,
                            dateSelectFocused: false,
                            focused: false,
                        });
                    }
                }}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                ref={(el) => (this.dateSelect = el)}
            >
                <div className="inputFieldDateInput">
                    <div className={'inputFieldDateInput__icon'}>
                        <Calendar />
                    </div>

                    <input
                        id={this.props.id}
                        data-test={this.props.testId}
                        ref={(el) => (this.dateInput = el)}
                        className={''}
                        aria-label={this.props.ariaLabel}
                        autoComplete={this.props.autoComplete}
                        type={'text'}
                        value={stringObject}
                        onKeyDown={this.onKeyDown}
                        onChange={(e) => {
                            this.validateDateInput(e.target.value);
                        }}
                        onFocus={() => {
                            this.setState({
                                focused: true,
                                dateSelectFocused: true,
                                dateSelectHasBeenOpened: false,
                                dateInputFocused: true,
                            });
                        }}
                        onBlur={() => {
                            if (!this.state.dateSelectHasBeenOpened) {
                                this.setState({
                                    focused: false,
                                    dateSelectFocused: false,
                                });
                            }
                            this.setState({ dateInputFocused: false });
                        }}
                        placeholder={
                            this.props.placeHolderText
                                ? this.props.placeHolderText
                                : 'Velg dato'
                        }
                    />
                </div>
                {this.props.type !== 'DATE--NO_PICKER' && (
                    <div className="inputFieldDateSelect">
                        <SingleDatePicker
                            key={'inputFieldDatePicker'}
                            readOnly={true}
                            date={dateObject}
                            onDateChange={(newDate) => {
                                this.onChange(newDate);
                                this.setState({
                                    focused: false,
                                    dateSelectFocused: false,
                                    dateSelectHasBeenOpened: false,
                                });
                            }}
                            focused={this.state.dateSelectFocused}
                            onFocusChange={(
                                { focused } /*TODO FLOW: boolean*/
                            ) => {
                                if (this.state.dateSelectHasBeenOpened) {
                                    this.setState({
                                        focused: focused,
                                        dateSelectFocused: focused,
                                        dateSelectHasBeenOpened: focused,
                                    });
                                }
                                if (!this.state.dateSelectHasBeenOpened)
                                    this.setState({
                                        dateSelectHasBeenOpened: true,
                                    });
                            }}
                            id={'inputFieldDatePicker'}
                            numberOfMonths={1}
                            daySize={36}
                            isOutsideRange={(day /*TODO FLOW:: moment*/) =>
                                this.props.minDate
                                    ? day.isBefore(this.props.minDate)
                                    : false
                            }
                            navPrev={<ArrowLeft className="calendarNavArrow" />}
                            navNext={
                                <ArrowRight className="calendarNavArrow" />
                            }
                        />
                    </div>
                )}
            </div>
        );
    };

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleTouchOutside);
    }

    // Needed to make sure that the datepicker closes when touching outside
    handleTouchOutside = (event) => {
        if (
            this.dateSelect !== undefined &&
            this.dateSelect.contains(event.target) === false
        ) {
            this.setState({
                focused: false,
                hover: false,
                dateSelectFocused: false,
                dateSelectHasBeenOpened: false,
                dateInputFocused: false,
            });
            if (this.dateInput) this.dateInput.blur();
        }
    };

    onChange = (newValue, optionalValidationValue) => {
        let {
            customValidation,
            customErrorMessage,
            optionalValidationCallBack,
            onChange,
        } = this.props;
        let valid =
            optionalValidationValue === undefined
                ? true
                : optionalValidationValue;
        if (customValidation) {
            valid = customValidation(newValue);
            this.setState({
                valid: valid,
                errorMessage: valid
                    ? undefined
                    : customErrorMessage
                    ? customErrorMessage
                    : 'Ugyldig format',
            });
            if (optionalValidationCallBack) optionalValidationCallBack(valid);
        } else {
            switch (this.props.type) {
                case 'PHONE_NUMBER':
                    valid = this.props.mandatory
                        ? this.mandatoryValidation_phoneNo(newValue)
                        : this.standardValidation_phoneNo(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Ugyldig format på telefonnummer',
                    });
                    break;
                case 'EMAIL':
                    valid = this.standardValidation_email(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Ugyldig format på epost',
                    });
                    break;
                case 'FULL_NAME':
                    valid = this.standardValidation_fullName(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Må inneholde fornavn og etternavn',
                    });
                    break;
                case 'NUMBER':
                    valid = this.standardValidation_number(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Kan bare inneholde tall',
                    });
                    break;
                case 'TEXT':
                    valid = this.mandatoryValidation(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Må fylles ut',
                    });
                    break;
                case 'PASSWORD':
                    valid = this.mandatoryValidation(newValue);
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Må fylles ut',
                    });
                    break;
                case 'DATE':
                    this.setState({
                        valid: valid,
                        errorMessage: valid
                            ? undefined
                            : customErrorMessage
                            ? customErrorMessage
                            : 'Dato må fylles ut (DD.MM.YYYY/DDMMYYYY)',
                    });
                    break;
                default:
                    break;
            }
            if (optionalValidationCallBack) optionalValidationCallBack(valid);
        }
        onChange(newValue);
    };

    onFocus = () => {
        this.setState({ focused: true });
        if (this.props.onFocus) this.props.onFocus();
    };

    onBlur = () => {
        this.setState({ focused: false });
        if (this.props.onBlur) this.props.onBlur();
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter' && this.props.onEnterPress) {
            this.props.onEnterPress();
        }
    };

    standardValidation_fullName = (name) =>
        this.mandatoryValidation(name) &&
        (!name || name.length === 0 || /(\w.+\s).+/i.test(name));

    standardValidation_phoneNo = (phoneNo) =>
        phoneNo.length === 0 || validPhoneNumber(phoneNo);

    mandatoryValidation_phoneNo = (phoneNo) =>
        this.mandatoryValidation(phoneNo) && validPhoneNumber(phoneNo);

    standardValidation_number = (num) =>
        this.mandatoryValidation(num) &&
        (!num || num.length === 0 || /^\d+$/.test(num));

    standardValidation_email = (email) =>
        this.mandatoryValidation(email) &&
        (!email ||
            email.length === 0 ||
            validEmail(email, this.props.multiValue));
    // (!email || email.length === 0 || /^\S+@\S+\.\S+$/.test(email));

    standardValidation_newPassword = (password) =>
        this.mandatoryValidation(password) &&
        (!password || password.length > 7);

    mandatoryValidation = (value) =>
        this.props.mandatory ? !!value || value.length !== 0 : true;

    validateDateInput = (input) => {
        if (input.length < 7) {
            this.onChange(input, false);
        } else {
            let parsedDate;
            if (input.indexOf('.') !== -1 && input.indexOf('-') !== -1) {
                this.onChange(input, false);
            } else if (input.indexOf('.') !== -1) {
                parsedDate = parseDotSeparatedDate(input);
                this.onChange(
                    parsedDate.valid ? parsedDate.date : input,
                    parsedDate.valid
                );
            } else if (input.indexOf('-') !== -1) {
                parsedDate = parseDashSeparatedDate(input);
                this.onChange(
                    parsedDate.valid ? parsedDate.date : input,
                    parsedDate.valid
                );
            } else if (input.length === 8) {
                parsedDate = parseNotSeparatedDate(input);
                this.onChange(
                    parsedDate.valid ? parsedDate.date : input,
                    parsedDate.valid
                );
            } else {
                this.onChange(input, false);
            }
        }
    };
}
export const InputField = connect((state) => ({
    theme: selectTheme(state),
}))(InputFieldComponent);
export default InputField;
