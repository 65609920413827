import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FeatureToggler } from '../../components/FeatureToggler';
import { P } from '../Typography';
import { Theme } from '../../../utilities/types';
import { Membership, Organization, PublicId } from '../../types/common';

const FeatureTogglesPage = lazy(
    () => import('../../pages/Admin/FeatureToggles/FeatureTogglesPage')
);
const LoadingPage = lazy(() => import('../LoadingPage/LoadingPage'));
const ErrorPage = lazy(() => import('../../pages/ErrorPage/ErrorPage'));
const DebugPage = lazy(() => import('../../pages/DebugPage/DebugPage'));
const AdminNewMemberCard = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminMemberCards/AdminNewMemberCard/AdminNewMemberCardContainer'
        )
);
const AdminProducts = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminProducts/AdminProductsContainer'
        )
);
const AdminNewEntityMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessage/AdminNewEntityMessage'
        )
);

const AdminImport = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminImportPage/AdminImportContainer'
        )
);
const GcreLanding = lazy(
    () => import('../../components/Partners/Gcre/GcreLandingContainer')
);
const OidcLoginSuccess = lazy(
    () => import('../../components/OidcLoginSuccess')
);
const AdminLocations = lazy(
    () => import('../../pages/Admin/subPages/AdminLocations/AdminLocations')
);
const AdminLocation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminLocations/AdminLocationContainer'
        )
);
const AdminNewLocation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminLocations/AdminNewLocation/AdminNewLocationContainer'
        )
);
const SharedReservation = lazy(
    () => import('../../pages/SharedReservation/SharedReservationContainer')
);
const AdminReservationExpenses = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminBilling/AdminReservationExpenses/AdminReservationExpensesContainer'
        )
);
const AdminAssignments = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminAssignmentsPage/AdminAssignments'
        )
);
const AdminAdminReservations = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminAdminReservations/AdminAdminReservations'
        )
);
const AdminReservationMessages = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminReservationMessages/AdminReservationMessages'
        )
);
const AdminReservationMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminReservationMessages/AdminReservationMessage/AdminReservationMessageContainer'
        )
);
const AdminDamage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminDamages/AdminDamage/AdminDamageContainer'
        )
);
const AdminEntityMessage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessage/AdminEntityMessageContainer'
        )
);
const AdminDamages = lazy(
    () => import('../../pages/Admin/subPages/AdminDamages/AdminDamages')
);
const AdminNewDamage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminDamages/AdminDamage/AdminNewDamage/AdminNewDamageContainer'
        )
);
const LoginComplete = lazy(() => import('../../components/LoginComplete'));
const LoginFailed = lazy(() => import('../../components/LoginFailed'));
const LinkOrCreateUser = lazy(
    () => import('../../components/LinkOrCreateUser/LinkOrCreateUserContainer')
);
const VerifyLicense = lazy(
    () => import('../../pages/VerifyLicense/VerifyLicense')
);
const LinkExistingUser = lazy(
    () =>
        import(
            '../../components/LinkOrCreateUser/LinkExistingUser/LinkExistingUserContainer'
        )
);

const SearchPage = lazy(() => import('../../pages/SearchPage'));
const ReservationsPage = lazy(() => import('../../pages/ReservationsPage'));
const InvoicesPage = lazy(() => import('../../pages/InvoicesPage'));
const PaymentFeedbackPage = lazy(
    () => import('../../pages/PaymentFeedbackPage')
);
const ProfilePage = lazy(() => import('../../pages/ProfilePage'));
const LocationPage = lazy(() => import('../../pages/LocationPage'));
const ForgotPasswordPage = lazy(
    () => import('../../pages/ForgotPasswordPage/ForgotPasswordPageContainer')
);
const Join = lazy(
    () => import('../../pages/ProfilePage/Components/Join/JoinContainer')
);

const Admin = lazy(() => import('../../pages/Admin'));
const AdminSearchPage = lazy(
    () => import('../../pages/Admin/subPages/AdminSearchPage')
);
const AdminMemberships = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberships')
);
const AdminMemberCards = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberCards')
);
const AdminMemberCard = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminMemberCards/AdminMemberCardContainer'
        )
);

const AdminMembership = lazy(
    () => import('../../pages/Admin/subPages/AdminMemberships/AdminMembership')
);

const AdminOverview = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpOverview/AdminHelpContainer'
        )
);

const AdminHelpSpecificSolution = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpOverview/AdminSpecificSolutionOverviewContainer'
        )
);

const AdminOverviewEdit = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpEdit/AdminHelpEditContainer'
        )
);

const AdminOverviewSpecificEdit = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminHelp/AdminHelpEdit/AdminHelpEditSpecificContainer'
        )
);

const AdminPersons = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminPersons')
);
const AdminNewPerson = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminNewPerson')
);
const AdminPerson = lazy(
    () => import('../../pages/Admin/subPages/AdminPersons/AdminPerson')
);
const AdminReservations = lazy(
    () => import('../../pages/Admin/subPages/AdminReservations')
);
const AdminNewReservation = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminReservations/AdminNewReservation'
        )
);
const AdminReservation = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminReservations/AdminReservation')
);
const AdminCars = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminCars')
);
const AdminNewCar = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminNewCar')
);
const AdminEntityMessages = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminEntityMessages/AdminEntityMessages'
        )
);

const AdminCar = lazy(
    () => import('../../pages/Admin/subPages/AdminCars/AdminCar')
);
const AdminModels = lazy(
    () => import('../../pages/Admin/subPages/AdminModels')
);
const AdminNewModel = lazy(
    () => import('../../pages/Admin/subPages/AdminModels/AdminNewModel')
);
const AdminModel = lazy(
    () => import('../../pages/Admin/subPages/AdminModels/AdminModel')
);
const AdminBilling = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminBilling/AdminBillingContainer')
);

const AdminBillingSummary = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminBilling/AdminBillingSummary/AdminBillingSummaryContainer'
        )
);
const AdminNewProduct = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminProducts/AdminNewProduct/AdminNewProductContainer'
        )
);
const AdminInvoices = lazy(
    () =>
        import('../../pages/Admin/subPages/AdminInvoice/AdminInvoiceContainer')
);
const AdminTripletexErrors = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminTripletexErrors/AdminTripletexErrors'
        )
);
const JoinTerms = lazy(
    () => import('../../pages/ProfilePage/Components/Join/JoinTerms')
);

const AdminCarImage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminCarImage/AdminCarImageContainer'
        )
);

const AdminNewCarImage = lazy(
    () =>
        import(
            '../../pages/Admin/subPages/AdminCarImage/AdminNewCarImage/AdminNewCarImageContainer'
        )
);

type Props = {
    theme: Theme;
    authorization?: string;
    admin?: boolean;
    organizations: Organization[];
    selectedOrgId?: PublicId;
    login: (userName: string, password: string) => void;
    logout: () => void;
    selectedMembership: Membership;
};

const Routes = (props: Props) => (
    <Suspense fallback={<div />}>
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/findcar" />} />
            <Route
                exact
                path="/loginComplete"
                render={(location) => <LoginComplete location={location} />}
            />
            <Route
                exact
                path="/oidc-login-success"
                render={(location) => <OidcLoginSuccess location={location} />}
            />
            <Route
                exact
                path="/linkOrCreateUser"
                render={(location) => <LinkOrCreateUser location={location} />}
            />
            <Route
                exact
                path="/partner/gcre/auth"
                render={(location) => (
                    <FeatureToggler
                        toggleName={'gcre landing page'}
                        hideIfToggleOff
                    >
                        <GcreLanding location={location} />
                    </FeatureToggler>
                )}
            />
            <Route
                exact
                path="/linkExistingUser"
                render={(location) => (
                    <LinkExistingUser location={location} onlyLink />
                )}
            />
            <Route exact path="/loginFailed" render={() => <LoginFailed />} />
            <Route
                exact
                path="/logout"
                render={() => {
                    props.logout();
                    return <Redirect to="/findcar" />;
                }}
            />
            <Route
                exact
                path="/index"
                render={() => <Redirect to="/findcar" />}
            />
            <Route
                exact
                path="/index.html"
                render={() => <Redirect to="/findcar" />}
            />
            <Route
                exact
                path="/findcar"
                render={(location) => <SearchPage location={location} />}
            />
            <Route
                exact
                path="/verifyLicense"
                render={(location) =>
                    props.authorization ? (
                        <VerifyLicense location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/reservations/:id"
                render={(location) =>
                    props.authorization ? (
                        <ReservationsPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/shared/:id"
                render={(location) => (
                    <SharedReservation
                        id={location.match.params.id}
                        location={location}
                    />
                )}
            />
            <Route
                path="/vipps/v2/fallback/:id"
                render={(location) =>
                    props.authorization ? (
                        <PaymentFeedbackPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/invoices/:id"
                render={() => <Redirect to="/invoices" />}
            />
            <Route
                path="/invoices"
                render={(location) =>
                    props.authorization &&
                    (!props.selectedMembership ||
                        props.selectedMembership.isOwner) ? (
                        <InvoicesPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/reservations"
                render={(location) =>
                    props.authorization ? (
                        <ReservationsPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/reset-password"
                render={(location) => (
                    <ForgotPasswordPage location={location} />
                )}
            />
            <Route
                path="/profile"
                render={(location) =>
                    props.authorization ? (
                        <ProfilePage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/join"
                render={(location) => (
                    <Join
                        location={location}
                        userLoggedIn={!!props.authorization}
                    />
                )}
            />
            <Route
                path="/terms"
                render={(location) => (
                    <JoinTerms theme={props.theme} location={location} />
                )}
            />
            <Route
                path="/locations/:id"
                render={(location) => <LocationPage location={location} />}
            />
            <Route exact path="/loading" render={() => <LoadingPage />} />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <Admin location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/feature-toggles"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <FeatureTogglesPage
                            theme={props.theme}
                            location={location}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/search"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminSearchPage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/user-management/memberships"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminMemberships location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/user-management/member-cards/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewMemberCard location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                path="/admin/billing-management/products/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewProduct location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                path="/admin/user-management/member-cards/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminMemberCard
                            location={location}
                            cardId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/user-management/member-cards"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminMemberCards location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/user-management/memberships/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminMembership
                            location={location}
                            membershipId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/user-management/persons"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminPersons location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/user-management/persons/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewPerson location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/user-management/persons/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminPerson
                            location={location}
                            personId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/user-management"
                render={() => <Redirect to="/admin/user-management/persons" />}
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/invoices"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminInvoices location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/invoice"
                render={() =>
                    props.authorization && props.admin ? (
                        <AdminBillingSummary />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/products"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminProducts location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/tripletex-errors"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminTripletexErrors location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/billing"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminBilling location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/billing-management/expenses"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminReservationExpenses location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/billing-management"
                render={() => (
                    <Redirect
                        to={{
                            pathname: '/admin/billing-management/billing',
                            search: 'needsReview=true',
                        }}
                    />
                )}
            />
            <Route
                exact
                path="/admin/reservation-management"
                render={() => (
                    <Redirect to="/admin/reservation-management/reservations" />
                )}
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/reservation-management/reservations"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminReservations location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                key={props.selectedOrgId}
                path="/admin/help/edit"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminOverview location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                key={props.selectedOrgId}
                path={'/admin/help/overview'}
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminHelpSpecificSolution location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                key={props.selectedOrgId}
                path="/admin/help/edit/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminOverviewEdit
                            location={location}
                            situationId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                key={props.selectedOrgId}
                path="/admin/help/edit/specific/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminOverviewSpecificEdit location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/reservation-management/messages"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminReservationMessages location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/damages"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminDamages location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/reservation-management/work-list"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminAdminReservations location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/reservation-management/reservations/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewReservation location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/reservation-management/reservations/:id"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminReservation
                            location={location}
                            reservationId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/reservation-management/messages/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminReservationMessage
                            location={location}
                            messageId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/damages/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewDamage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/damages/:id"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminDamage
                            location={location}
                            damageId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/fleet-management/cars"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminCars location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/fleet-management/assignments"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminAssignments location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/fleet-management/cars/new"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        props.organizations.length === 0 ? (
                            <P>Henter organisasjoner.</P>
                        ) : (
                            <AdminNewCar location={location} />
                        )
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/fleet-management/entity-messages"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminEntityMessages location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/fleet-management/entity-messages/new"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewEntityMessage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/fleet-management/entity-messages/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminEntityMessage
                            location={location}
                            messageId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/fleet-management/cars/:id"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        props.organizations.length === 0 ? (
                            <P>Henter organisasjoner.</P>
                        ) : (
                            <AdminCar
                                location={location}
                                carId={location.match.params.id}
                            />
                        )
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                path="/admin/fleet-management/models"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminModels location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/fleet-management/car-images"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminCarImage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/fleet-management/car-images/new"
                key={props.selectedOrgId} // force re-render when organization changes
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewCarImage location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/fleet-management/locations"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminLocations location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                path="/admin/fleet-management/locations/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewLocation location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            <Route
                exact
                path="/admin/fleet-management/locations/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminLocation
                            location={location}
                            locationId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />

            {/*todo: add admin new Location*/}
            <Route
                exact
                key={props.selectedOrgId} // force re-render when organization changes
                path="/admin/fleet-management/models/new"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminNewModel location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/admin/fleet-management/models/:id"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminModel
                            location={location}
                            modelId={location.match.params.id}
                        />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                exact
                path="/admin/fleet-management"
                render={() => <Redirect to="/admin/fleet-management/cars" />}
            />
            <Route
                path="/admin/billing-management/import"
                render={(location) =>
                    props.authorization && props.admin ? (
                        <AdminImport location={location} />
                    ) : (
                        <Redirect to="/findcar" />
                    )
                }
            />
            <Route
                path="/debug-mode"
                render={() => <DebugPage admin={props.admin} />}
            />
            <Route
                render={(history) => (
                    <ErrorPage history={history} type={'UNKNOWN_URL'} />
                )}
            />
        </Switch>
    </Suspense>
);

export default Routes;
