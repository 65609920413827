import React, { ReactElement } from 'react';
import { useAuthenticatedQuery } from '../../utilities/apiUtils';

const useToggle = (toggleName: string) => {
    return useAuthenticatedQuery<boolean>(
        `/api/feature-toggles/${toggleName}`,
        {
            staleTime: 900000,
            cacheTime: 900000,
            parseData: (json) => json.entity,
        }
    );
};

type FeatureTogglerProps = {
    toggleName: string;
    children: React.ReactElement;
    hideIfToggleOff?: boolean;
    overWritePropsIfToggleOn?: Object;
};

type FeatureToggleIfElseProps = {
    ifToggleOn: React.ReactElement;
    ifToggleOff: React.ReactElement;
    toggleName: string;
};

/**
 * Wrapper component for feature toggling.
 * Props:
 *      toggleName: name of the toggle
 *      children: wrapped component
 *      hideIfToggleOff: if  true, nothing is rendered if the toggle is off
 *      overWritePropsIfToggleOn: object with properties which will be added to the wrapped component if the toggle is on
 */

export const FeatureToggler = (props: FeatureTogglerProps): ReactElement => {
    const { data, isError, isLoading } = useToggle(props.toggleName);

    const toggleOn = !isLoading && !isError && data && data === true;
    if (props.hideIfToggleOff && !toggleOn) return <></>;
    return toggleOn
        ? (React.cloneElement(
              props.children,
              props.overWritePropsIfToggleOn
          ) as ReactElement)
        : props.children;
};

export const FeatureToggleIfElse = (props: FeatureToggleIfElseProps) => {
    const { data, isError, isLoading } = useToggle(props.toggleName);

    return !isLoading && !isError && data && data === true ? (
        <>{props.ifToggleOn}</>
    ) : (
        <>{props.ifToggleOff}</>
    );
};
