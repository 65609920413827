import React from 'react';
import Button, { ButtonTypes } from './Button';
import { Theme } from '../../../utilities/types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { selectTheme } from '../../duck/selectors';
import { State } from '../../../state/store';

type Props = {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    theme: Theme;
    buttonType?: ButtonTypes;
};

const AdminSaveButtonComponent = (props: Props) => (
    <div
        className={classnames(
            'adminPage__SaveButtonContainer',
            props.className
        )}
        style={{
            display: 'flex',
            justifyContent: 'flex-end',
        }}
    >
        <Button
            label={props.disabled ? 'Lagret' : 'Lagre'}
            onClick={() => {
                !props.disabled && props.onClick();
            }}
            buttonType={props.buttonType ?? 'basicHoverButton'}
            hoverColor={
                props.disabled
                    ? props.theme.colors?.disabled__wcag
                    : props.theme.colors?.primary__dark
            }
            bgColor={
                props.disabled
                    ? props.theme.colors?.disabled
                    : props.theme.colors?.primary
            }
            styleOverride={{
                padding: '6px 8px',
                borderRadius: '4px',
                color: 'white',
                fontWeight: 'bold',
            }}
        />
    </div>
);

export const AdminSaveButton = connect((state: State) => ({
    theme: selectTheme(state),
}))(AdminSaveButtonComponent);
